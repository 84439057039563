import React, { useState, useEffect } from "react";
import "./App.css";
import questions from "./questions.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const App = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState(""); // "success" or "error"
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [timer, setTimer] = useState(5400); // 90 minutes in seconds

  const randomizeQuestions = () => {
    const randomizedQuestions = [...questions].sort(() => Math.random() - 0.5);
    return randomizedQuestions.slice(0, 60); // Limit to 60 questions
  };

  const [shuffledQuestions] = useState(randomizeQuestions());

  useEffect(() => {
    if (timer > 0 && !quizCompleted) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setQuizCompleted(true);
      showFinalResults();
    }
  }, [timer, quizCompleted]);

  const handleCheckboxChange = (index) => {
    const selectedAnswerSet = new Set(
      selectedAnswers[currentQuestionIndex] || []
    );
    if (selectedAnswerSet.has(index)) {
      selectedAnswerSet.delete(index);
    } else {
      selectedAnswerSet.add(index);
    }
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[currentQuestionIndex] = Array.from(selectedAnswerSet);
    setSelectedAnswers(updatedAnswers);
  };

  const handleRadioChange = (index) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[currentQuestionIndex] = [index];
    setSelectedAnswers(updatedAnswers);
  };

  const handleSubmit = () => {
    const currentQuestion = shuffledQuestions[currentQuestionIndex];

    if (!currentQuestion) return;

    const correctAnswers = currentQuestion.answer;
    const selectedArray = selectedAnswers[currentQuestionIndex] || [];
    const isCorrect =
      correctAnswers.every((ans) => selectedArray.includes(ans)) &&
      selectedArray.length === correctAnswers.length;

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
      setPopupType("success");
      setPopupMessage(`<h2><strong>Correct!</strong></h2>`);
      setTimeout(() => {
        if (currentQuestionIndex < shuffledQuestions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
          setQuizCompleted(true);
          showFinalResults();
        }
        closePopup();
      }, 400);
    } else {
      setPopupType("error");
      const allOptionsText = currentQuestion.options
        .map((option, index) => {
          return correctAnswers.includes(index)
            ? `&#9989; ${option}` // Mark correct answer
            : `&#10060; ${option}`;
        })
        .join("\n");

      setPopupMessage(
        `<strong>Wrong Answer!</strong><hr>${currentQuestion.question}\n\n ${allOptionsText}`
      );
      setShowPopup(true);
    }
    setShowPopup(true);

    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setQuizCompleted(true);
      showFinalResults();
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const showFinalResults = () => {
    const totalQuestions = shuffledQuestions.length;
    const percentageScore = ((score / totalQuestions) * 100).toFixed(2);
    setPopupMessage(
      `Quiz Completed! Your score: ${score}/${totalQuestions} (${percentageScore}%)`
    );
    setPopupType("success");
    setShowPopup(true);
  };

  const currentQuestion = shuffledQuestions[currentQuestionIndex];
  const isMultipleAnswers = currentQuestion.answer.length > 1;

  const totalQuestions = shuffledQuestions.length;
  const percentageScore =
    totalQuestions > 0
      ? ((score / currentQuestionIndex || 0) * 100).toFixed(2)
      : 0;

  // Check if any answer is selected
  const isAnswerSelected =
    selectedAnswers[currentQuestionIndex] &&
    selectedAnswers[currentQuestionIndex].length > 0;

  // Convert timer to minutes and seconds
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  return (
    <div className="App">
      <h1>SuiteFoundation Reviewer</h1>
      {!quizCompleted ? (
        <div className="question-container">
          <h2>
            {currentQuestionIndex + 1}. {currentQuestion.question}{" "}
            {isMultipleAnswers && (
              <span>(Choose {currentQuestion.answer.length})</span>
            )}
          </h2>
          <form className="options">
            {currentQuestion.options.map((option, index) => {
              const isSelected = (
                selectedAnswers[currentQuestionIndex] || []
              ).includes(index);
              return (
                <div className="optionsWrapper" key={index}>
                  {isMultipleAnswers ? (
                    <>
                      <input
                        type="checkbox"
                        id={`option-${index}`}
                        checked={isSelected}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <label htmlFor={`option-${index}`}>{option}</label>
                    </>
                  ) : (
                    <>
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        id={`option-${index}`}
                        checked={isSelected}
                        onChange={() => handleRadioChange(index)}
                      />
                      <label htmlFor={`option-${index}`}>{option}</label>
                    </>
                  )}
                </div>
              );
            })}
          </form>
          <button
            className="submit-button"
            onClick={handleSubmit}
            disabled={!isAnswerSelected}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      ) : null}

      <div className="score-display">
        <h5>
          Current Score: <span className={percentageScore < 60 ? `low` : percentageScore < 80 ? `medium` : `high`}> {percentageScore}%</span>
          <br />
          <br />
          Total Correct Answers:{" "}
          <span>
            {score}/{totalQuestions}
          </span>
          <br />
          <br />
          <span>
            Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        </h5>
        <div className="timer">

        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className={`popup-content fade-in ${popupType}`}>
            <span className="close" onClick={closePopup}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
            <p
              dangerouslySetInnerHTML={{
                __html: popupMessage.replace(/\n/g, "<br />"),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
